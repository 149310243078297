import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.concat.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var twoPoem = function twoPoem() {
  return import("./common/twoPoem");
};

var proTag = function proTag() {
  return import("./common/proTag");
};

var articleList = function articleList() {
  return import("./articleList");
};

var myFooter = function myFooter() {
  return import("./common/myFooter");
};

export default {
  components: {
    twoPoem: twoPoem,
    proTag: proTag,
    articleList: articleList,
    myFooter: myFooter
  },
  data: function data() {
    return {
      sortId: this.$route.query.sortId,
      labelId: this.$route.query.labelId,
      sort: null,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        sortId: this.$route.query.sortId,
        labelId: this.$route.query.labelId
      },
      articles: []
    };
  },
  computed: {},
  watch: {
    $route: function $route() {
      this.pagination = {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        sortId: this.$route.query.sortId,
        labelId: this.$route.query.labelId
      };
      this.articles.splice(0, this.articles.length);
      this.sortId = this.$route.query.sortId;
      this.labelId = this.$route.query.labelId;
      this.getSort();
      this.getArticles();
    }
  },
  created: function created() {
    this.getSort();
    this.getArticles();
  },
  mounted: function mounted() {},
  methods: {
    pageArticles: function pageArticles() {
      this.pagination.current = this.pagination.current + 1;
      this.getArticles();
    },
    getSort: function getSort() {
      var _this = this;

      var sortInfo = this.$store.state.sortInfo;

      if (!this.$common.isEmpty(sortInfo)) {
        var sortArray = sortInfo.filter(function (f) {
          return f.id === parseInt(_this.sortId);
        });

        if (!this.$common.isEmpty(sortArray)) {
          this.sort = sortArray[0];
        }
      }
    },
    listArticle: function listArticle(label) {
      var _this2 = this;

      this.labelId = label.id;
      this.pagination = {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        sortId: this.$route.query.sortId,
        labelId: label.id
      };
      this.articles.splice(0, this.articles.length);
      this.$nextTick(function () {
        _this2.getArticles();
      });
    },
    getArticles: function getArticles() {
      var _this3 = this;

      this.$http.post(this.$constant.baseURL + "/article/listArticle", this.pagination).then(function (res) {
        if (!_this3.$common.isEmpty(res.data)) {
          _this3.articles = _this3.articles.concat(res.data.records);
          _this3.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};